import React from 'react'
import './Footer.css';

const Footer = () => {
  return(
    <div className='footer'>
        <p>copyright @ 2024,Cryptoplace - All Right Reserved</p>
    </div>
  )
}

export default Footer